<template>
<!-- 赛事-赛事活跃时间分析 -->
    <div class="sportnav darkBack">
        <div>赛事活跃时间分析</div>
        <div id="timeAnalysis"></div>
    </div>
</template>

<script>
import bus from "../../../assets/js/bus";

export default {
    data () {
        return {
            sport:{
                data:['2月', '3月', '4月', '5月', '6月', '7月', '8月'],
                number:[1010, 1670, 2240, 2750, 3610, 2630, 1420],
            },
        }
    },
    methods: {
        lineOption(id, number){
            // let canye = this.$echarts.init(document.getElementById(id));
            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }
            var option = {
                xAxis: {
                    type: 'category',
                    axisLabel: {               // x轴字体的颜色
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E299",
                            fontSize: '0.13rem',
                        },                           
                    },
                    offset: 4.5,
                    axisTick: {
                        show: false
                    },
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#2D94D0',
                        }
                    },
                    data: number.data
                },
                grid:{
                    left:"16%",
                    right:"10%",
                    top:"15%",
                    bottom: "18%"
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {               // y轴字体的颜色
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E299",
                            fontSize: '0.13rem',
                        },                           
                    },
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: ['#2D94D040'],
                            width: 1,
                            type: 'solid',
                        },
                　　}
                },
                series: [{
                    data: number.number,
                    symbol: 'circle',//拐点样式
                    symbolSize: 9,   //拐点圆的大小
                    type: 'line',
                    itemStyle : { 
                        normal : { 
                            color:'#E5813A', //改变折线点的颜色
                            lineStyle:{ 
                                color:'#E5813A' //改变折线颜色
                            } 
                        } 
                    }, 
                }]
            };
            canye.setOption(option);
			// 图表自动缩放
			window.addEventListener("resize",function(){
			  canye.resize();
			});
        },
    },
    mounted () {
        // this.lineOption("timeAnalysis", this.sport)
    },
    created () {
        let that = this;
        bus.$on('time_analyse',res=>{
            // console.log(res)
            that.sport = {
                data:[],
                number:[],
            }
            res.forEach((item,index)=>{
                that.sport.data.push(item.str);
                that.sport.number.push(item.match_num);
            })
            that.lineOption("timeAnalysis", that.sport)
        })
    }
}
</script>

<style scoped>
.sportnav{
    width:5.96rem;
    height:3.30rem;
    color:#FFFFFF;
    padding:0.2rem;
    font-size: 0.2rem;
    position: relative;
}
#timeAnalysis{
    width:5.60rem;
    height:2.70rem;
    /* border:1px solid red; */
}
</style>